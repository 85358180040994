<template>
  <div class="MonitorStatus">
    <template v-if="!showDropdown">
      <div class="badge"
           :class="classes" >
        <div class="status-text" v-if="!monitor.is_running">Paused</div>
        <div class="status-text" v-else-if="!hasNodes">
          Not Activated
        </div>
        <div class="status-text" v-else>
          {{ monitor.status | monitorStatus }}
        </div>
      </div>
    </template>

    <template v-else>
      <div ref="badge"
           class="badge badge-hoverable"
           :class="classes">
        <div class="status-text" v-if="!monitor.is_running">Paused</div>
        <div class="status-text" v-else-if="!hasNodes">
          Not Activated
        </div>
        <div class="status-text" v-else>
          {{ monitor.status | monitorStatus }}
        </div>
        <icon-dropdown width="16"
                       height="16"
                       color="#155724"
                       class="icon-dropdown" />
      </div>

      <div ref="dropdown" class="dropdown">
        <template v-if="monitorForNodes">
          <monitor-nodes :monitor="monitorForNodes"
                         @toggle="onNodeToggle" />
          <no-active-nodes-alert class="no-active-nodes container"
                                 v-if="!hasActiveNodes" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import monitorsApi from '@/api/monitorsApi.js'
import MonitorNodes from '@/components/Monitor/MonitorNodes/MonitorNodes.vue'
import NoActiveNodesAlert from '@/components/Monitor/MonitorNodes/MonitorNodesNoActiveNodesAlert.vue'

export default {
  components: {
    MonitorNodes,
    NoActiveNodesAlert
  },

  props: {
    // Monitor entity
    monitor: {
      required: true,
      type: Object
    },

    // Whether there should be a dropdown with
    // detailed info when user clicks on it
    detailed: {
      required: false,
      type: Boolean,
      default: true
    },

    // Should it stretch or behave line inline element
    stretch: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      tippyInstance: null,
      monitorForNodes: null
    }
  },

  methods: {
    setTippy () {
      if (this.tippyInstance) {
        return
      }

      this.tippyInstance = tippy(this.$refs.badge, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: async () => {
          this.monitorForNodes = await monitorsApi.find(this.monitor.id)
        }
      })
    },

    removeTippy () {
      if (this.tippyInstance) {
        this.tippyInstance.destroy()
      }
    },

    async onNodeToggle () {
      this.monitorForNodes = await monitorsApi.find(this.monitor.id)
    }
  },

  computed: {
    classes () {
      let classes = []

      if (this.monitor.is_running === false) {
        classes.push('disabled')
      } else if (this.monitor.status === 'online' || this.monitor.status === 'offline') {
        classes.push(this.monitor.status)
      } else {
        classes.push('checking')
      }

      if (this.showDropdown) {
        classes.push('badge-hoverable')
      }

      if (this.stretch) {
        classes.push('d-flex')
      } else {
        classes.push('d-inline-flex')
      }

      return classes
    },

    showDropdown () {
      if (this.monitor.status !== 'online' && this.monitor.status !== 'offline') {
        return false
      }

      return this.detailed
    },

    hasActiveNodes () {
      return this.monitorForNodes.nodes.some(node => node.active)
    },

    hasNodes () {
      return this.monitor.nodes.some(node => node.active)
    }
  },

  watch: {
    monitor: {
      handler: function () {
        this.$nextTick(() => {
          if (this.showDropdown) {
            this.setTippy()
          } else {
            this.removeTippy()
          }
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorStatus {
    .badge {
      @include badge;

      display: flex;
      align-items: center;

      .icon-dropdown {
        transition: 0.2s all;
      }

      &[aria-expanded = "true"] .icon-dropdown {
        transform: rotate(180deg);
      }

      .status-text {
        flex: 1 0;
      }

      &.online {
        @include badge-success;
      }

      &.offline {
        @include badge-danger;
      }

      &.disabled {
        @include badge-disabled;
        background-color: #eee;
      }

      &.checking {
        @include badge-warning;

        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: placeholderAnimate;
        background: #f6f7f8; // Fallback
        background: linear-gradient(to right, #ffdf80 2%, #ffecb3 18%, #ffdf80 33%);
        background-size: 1300px; // Animation Area
      }
    }

    .dropdown {
      padding: 20px 0;
      width: 1000px;
    }

    .no-active-nodes {
      margin-top: 20px;
    }

    @keyframes placeholderAnimate {
      0%{ background-position: -650px 0; }
      100%{ background-position: 650px 0; }
    }
  }
</style>
