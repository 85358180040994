<template>
  <nav class="LayoutHeaderNavigation">
    <router-link class="link"
                 :to="getRoute('overview')">Overview</router-link>
    <router-link class="link"
                 :to="getRoute('nodes')">Locations</router-link>
    <router-link class="link"
                 :to="getRoute('failedChecks')">Failed Checks</router-link>
    <router-link v-if="user.role !==  role.Viewer && user.role !== role.Accountant" class="link"
                 :to="getRoute('notifications')">Alerts</router-link>
    <router-link v-if="user.role !==  role.Viewer && user.role !== role.Accountant" class="link"
                 :to="getRoute('settings')">Advanced Settings</router-link>
    <router-link v-if="user.role !==  role.Viewer && user.role !== role.Accountant" class="link"
                 :to="getRoute('delete')">Delete Monitor</router-link>
  </nav>
</template>

<script>
import { Role } from '@/_helpers/role.js'
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      role: Role
    }
  },

  methods: {
    getRoute (name) {
      return {
        name: `monitors.single.${name}`,
        params: {
          id: this.monitor.id
        }
      }
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .LayoutHeaderNavigation {
    display: flex;
    flex-wrap: wrap;
    //margin: 0 -12px;
    padding-top: 40px;

    .link {
      display: block;
      padding: 0 12px 12px 12px;
      font-size: 15px;
      font-weight: 500;
      transition: color 0.2s;
      margin-bottom: -1px;

      &:not(.router-link-active):not(.router-link-exact-active) {
        color: lighten(map-get($colors, gray-1), 20%);

        &:hover {
          color: map-get($colors, gray-1);
          border-bottom: 1px solid lighten(map-get($colors, gray-1), 20%);
        }
      }

      &.router-link-active {
        border-bottom: 2px solid map-get($colors, purple-1);
      }
    }

    .soon {
      opacity: 0.5;

      &:hover {
        color: lighten(map-get($colors, gray-1), 20%) !important;
      }
    }
  }
</style>
