<template>
  <div class="Layout" v-if="monitor">
    <monitor-header :monitor="monitor" />
    <router-view :monitor-id="id" />
  </div>
</template>

<script>
import MonitorHeader from '@/components/Monitor/MonitorHeader/MonitorHeader.vue'
import monitorsApi from '@/api/monitorsApi.js'

export default {
  components: {
    MonitorHeader
  },

  props: {
    id: {
      required: true
    }
  },

  data () {
    return {
      monitor: null
    }
  },

  created () {
    this.loadMonitor()
  },

  methods: {
    async loadMonitor () {
      this.monitor = await monitorsApi.find(this.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .Layout {

  }
</style>
